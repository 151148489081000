$(document).ready(function () {
    var path = window.location.href;

    // 仅在解决方案页面中执行
    if (path.indexOf('solutionfund') > -1 ||
        path.indexOf('sol_distribution') > -1 || // 多级代理分销
        path.indexOf('sol_store') > -1 || // 多门店系统
        path.indexOf('sol_retail') > -1 || // 新零售
        path.indexOf('profit_sharing') > -1 // 全场景分账
    ) {

        // wave animate
        $('#img1').seamlessRolling({ speed: 3.5 });
        $('#img2').seamlessRolling({ speed: 2.2 });
        $('#img3').seamlessRolling({ speed: 1 });


        // top contact-us
        $('#contact-us-top').off('click').on('click', function () {
            $('html, body').animate({
                scrollTop: $(".solution-contact").offset().top - 150
            })
        });
    }
});