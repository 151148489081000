// $(function(){
// 	// 首页轮播
// 	var size = $('.case-list li').length, i = 0,
// 		imgArr = ['../assets/img/xhs-bg.png', '../assets/img/kid-bg.png', '../assets/img/dxys-bg.png'];

// 	$('.case-list li').css('width', 100/size + '%');
// 	$('.case-list').css('width', 100*size + '%');

// 	for(var j=0; j<size; j++){
// 		$('.case-num').append('<span></span>');
// 		$('.case-list li').eq(j).css('background-image', 'url('+imgArr[j]+')');
// 	}
// 	$('.case-num').find('span').first().addClass('on');

// 	var w = parseInt($('.case-list li').css('width'));

// 	/*自动轮播*/
// 	var t = setInterval(function () {
// 		i++;
// 		move();
// 	},3000);

// 	/*鼠标悬停事件*/
// 	$(".index-case-moudle").hover(function () {
//         clearInterval(t);
//     }, function () {
//         t = setInterval(function () { i++; move(); }, 3000);
//     });

//     /*鼠标滑入原点事件*/
//     $(".case-num span").hover(function(){
//     	var index = $(this).index();
//     	$(".case-list").stop().animate({ left: -w*index + 'px' }, 500);
//     	$(this).addClass("on").siblings().removeClass("on");
//     });

//     /*向左按钮*/
//     $(".arrow-left").click(function () {
//         i--;
//         move();
//     })

//     /*向右按钮*/
//     $(".arrow-right").click(function () {
//         i++;
//         move();
//     })

// 	var move = function(){
//         if (i > size - 1) {
//             $(".case-list").stop().animate({ left: 0 }, 500);
//             i = 0;
//         }
//         if (i < 0) {
//             $(".case-list").stop().animate({ left: -w*(size-1) + 'px' }, 500);
//             i = size - 1;
//         }

//         $(".case-list").stop().animate({ left: -w*i + 'px' }, 500);

//         if (i > size - 1) {
//             $(".case-num span").eq(0).addClass("on").siblings().removeClass("on");
//         } else {
//             $(".case-num span").eq(i).addClass("on").siblings().removeClass("on");
//         }
//     };

// 	// 解决方案页 tab
// 	$('.tabName').children().click(function(){
// 		$(this).addClass('active').siblings().removeClass('active');
// 		$($(this).attr('data-tab')).show().siblings().hide();
// 	});

// 	// 解决方案页--业务场景移动端slide
// 	$('#scenSlide').splide();
// });
