function stickyPricePlan() {
  if($(window).width() > 760){
    var controller = new ScrollMagic.Controller();
    // create a scene
    var scene = new ScrollMagic.Scene({
      triggerElement: "#pinned-trigger-pricePlan",
      triggerHook: 0,
      duration: 0,
    })
    .setClassToggle('body', 'price-plan-stacky')
    .setPin("#pinned-element-pricePlan") // pins the element for the the scene's duration
    .addTo(controller); // assign the scene to the controller
  }
}
