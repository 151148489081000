var setStickyNavBar = function () {
    // if ($('.top-bar-wrapper').hasClass('is-fixed')) {
    //
    // }
    var controller = new ScrollMagic.Controller();
    var navBarTween = TweenMax.to(".top-bar-wrapper", 1, { className: "+=fixed2" });
    var navBar = new ScrollMagic.Scene({ triggerHook: 0.6, duration: 50, offset: $('.banner-box').height() })
        .setTween(navBarTween)
        .addTo(controller);
}

var setStickyforPositon = function () {
    var $membersContainer = $("#membersContainer"),
        $product = $("#product").height(),
        $engineer = $("#engineer").height(),
        $misc = $("#misc").height(),
        $operations = $("#operations").height(),
        $marketing = $("#marketing").height(),
        $nav = $('#team-members .team-nav'),
        n = 0, n1 = $product, n2 = n1 + $engineer, n3 = n2 + $misc, n4 = n3 + $operations,
        n5 = n4 + $marketing;
    $membersContainer.on('scroll', testOffset);

    function testOffset(e) {
        n = $membersContainer.scrollTop() + 42;
        if (n <= n1) {
            $("ul.team-nav li a").removeClass("active");
            $("ul.team-nav li:eq(0) a").addClass("active");
        } else if (n > n1 && n <= n2) {
            $("ul.team-nav li a").removeClass("active");
            $("ul.team-nav li:eq(1) a").addClass("active");
        } else if (n > n2 && n <= n3) {
            $("ul.team-nav li a").removeClass("active");
            $("ul.team-nav li:eq(2) a").addClass("active");
        } else if (n > n3 && n <= n4) {
            $("ul.team-nav li a").removeClass("active");
            $("ul.team-nav li:eq(3) a").addClass("active");
        } else if (n > n4) {
            $("ul.team-nav li a").removeClass("active");
            $("ul.team-nav li:eq(4) a").addClass("active");
        }
    }
}

var setMobileNav = function () {
    $('.title-bar__toggle').click(function () {

        // 在点击的时候，隐藏广告栏目
        $('.activity-box').toggleClass('hide');

        $('body').toggleClass('no-scrolling');
        $('.mobile-nav').toggleClass('show');
        // $('.ui-mask').toggleClass('show');
        $('.title-bar').toggleClass('title-bar--active');
    });

    $(window).on('resize', function () {
        var $w = $(window).width();
        if ($w > 640) {
            $('.mobile-nav').removeClass('show');
            $('.ui-mask').removeClass('show');
        }
    });
};

// 请求banner 配置, 首页运行
if ($('#bannerTemplate').html()) {
    var domain = location.hostname.indexOf('pinpula.com') > -1 ? 'pinpula.com' : 'pingxx.com';

    var url = 'https://dashboard.' + domain + '/api/callback/banner/image?scenario=official-site'
    $.get(url, function (data) {
        if (data.status === true && data.data.message && data.data.message.enabled === 1) {
            var message = data.data.message;

            var template = _.template($('#bannerTemplate').html()),
                bannerBox = $('#banner-box');

            var newString = template(message);

            // 填充到html 中
            bannerBox.html(newString);

            // 为 top 元素添加对应的css
            $('#index-top-bar').css('top', bannerBox.height() + 'px');
        }

    });


    // 在点击 banner 的时候，跳转到活动页面
    $('#banner-box').on('click', '.activity-box', function () {

        var url = $(this).data('src');

        if (url) {
            var win = window.open('loading page');
            win.location.href = url;
        }
    });


    // 此处绑定点击广告banner 的关闭按钮后， 删除 DOM
    $('#banner-box').on('click', '.close-btn', function (event) {
        event.stopPropagation();
        var $El = $('.activity-box');
        $El.animate({ height: '0' }, 200, 'linear', function () {
            $El.remove();
        });
    });
}
