$(document).ready(function () {
    var path = window.location.href;

    // 仅在 场景页面和百科页面执行
    if (
        path.indexOf('wiki') > -1 ||
        path.indexOf('scenes') > -1
    ) {
        $('.scenes-box,.scenes-qr-box').on('click', function () {
            window.location.href = '/wiki/' + $(this).attr('url');
        })

    }
});