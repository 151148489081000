// Animation helper
var getRandomNumber = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Landing hero
var setDeviceWallScrollPosition = function (e, animate){
  var devicePosition = e.position().left;
  var wrapPosition = $(".device-wall").position().left;
  var viewportWidth = $(".device-wall-wrap").width();
  var viewportOffset = viewportWidth / 2 - e.width() / 2;
  var offset = devicePosition - wrapPosition - viewportOffset;
  if (animate) {
    $(".device-wall").velocity("scroll", {
      axis: "x",
      offset: offset,
      duration: 500,
      container: $(".device-wall-wrap")
    });
  } else {
    $(".device-wall-wrap").scrollLeft(offset);
  }
}

var animateHero = function () {
  $(".device-wall").addClass("is-animated");
  $(".hero-copy").addClass("is-animated").delay(200).queue(function(){
    $(".device-wall li").each(function(i){
      var device = $(this);
      setTimeout(function() {
        device.addClass('is-animated');
      }, 150*i);
    });
  });
}

var replayAnimation = function () {
  $(".hero-copy").removeClass("is-animated");
  $(".device-wall").removeClass("is-animated");
  $(".device-wall li").each(function(i) {
    $(this).removeClass("is-animated");
  });
  //animateHero();
  setTimeout(function(){
    animateHero();
  }, 1000);
}

// Box animation (financing)
var coinId = 0;

var heroCoinDropAnimation = function () {
  var coin = $("<div class='hero-coin hero-coin-" + coinId + "'></div>");
  coin.css({
    left: getRandomNumber(100,1000),
    backgroundColor: "HSL(" + getRandomNumber(170,210) + ", 73%, " + getRandomNumber(50,80) + "%)"
  });
  $(".hero-bg--coin").append(coin);
  $(".hero-coin-" + coinId).velocity({
    properties: {
      top: 400,
      left: "50%",
      opacity: 0,
      scale: 0.2,
      rotateX: getRandomNumber(-45,90),
      rotateY: getRandomNumber(-90,35),
      rotateZ: getRandomNumber(-10,10)
    },
    options: {
      duration: 5000,
      complete: function (){coin.remove()}
    }
  });
  coinId = coinId + 1;
}

var uberTitleAnimation = function () {
  var cur = $("#carousel-first").find(".hero-title-carousel-reveal");

  if (cur.length === 0){
    var $lis = $("#carousel-first li");
    $lis.removeClass("cubic-easeinout--fast");
    $lis.addClass("hero-title-carousel-reveal").removeClass("hero-title-carousel-hide");
    $($lis[0]).removeClass("hero-title-carousel-reveal");
    setTimeout(function(){
      $lis.addClass("cubic-easeinout--fast");
      $($lis[0]).addClass("hero-title-carousel-hide");
      $($lis[0]).next().removeClass("hero-title-carousel-reveal");
    }, 100);

  }

  var cur = $(cur[0]);
  cur.prev().addClass("hero-title-carousel-hide");
  cur.removeClass("hero-title-carousel-reveal");
}

var cubeAnimation = function() {
  $(".hero-bg--cube").find(".cube").removeClass("start");
}

// Countdown drop animation
var countdownDropAnimation = function () {
  var base = $(".countdown-bg");
  var coin = $("<div class='countdown-bg__piece countdown-bg__piece-" + coinId + "'></div>");
  var hue = getRandomNumber(170,210);
  var saturation = getRandomNumber(65,85);
  var color = "hsl(" + hue + ", 75%, " + saturation + "%)";
  var shadowColor = "hsla(" + hue + ", 73%, " + saturation + "%, 0.25)";
  coin.css({
    left: getRandomNumber(100,base.width()-100),
    backgroundColor: color,
    boxShadow: "0px 20px 50px " + shadowColor
  });
  base.append(coin);
  $(".countdown-bg__piece-" + coinId).velocity({
    properties: {
      top: base.height()-150,
      left: "50%",
      opacity: [0, "easeInQuint"],
      scale: 0.6,
      rotateX: getRandomNumber(-5,5),
      rotateY: getRandomNumber(-45,45),
      rotateZ: getRandomNumber(-20,20),
      boxShadowY: 20,
      boxShadowBlur: 20
    },
    options: {
      duration: 8000,
      complete: function (){coin.remove()}
    }
  });
  coinId = coinId + 1;
}
var screenSlide = function(options) {
  var element = options.element;
  var slideWidth = options.slideWidth;
  var currentIndex = options.currentIndex;
  var transitionString, translateString;
  var currentDistance = slideWidth * currentIndex;
  translateString = "translate3d(-"+ currentDistance + "px,0,0)";
  transitionString = "all 0.35s ease-in";
  element.css({"-webkit-transform":translateString,"transform":translateString,"-webkit-transition":transitionString,"transition":transitionString});
}

$('#balance-scene-menu').on('click', 'a', function(event) {
  event.preventDefault();
  var index = $(this).attr('data-target');
  var element = $(".balance-scene-container .swiper-wrapper");
  $('#balance-scene-menu a').removeClass("active");
  $(this).addClass("active");
  $(".balance-scene-canvas .product-bg").removeClass("active");
  $(".balance-scene-container .content").removeClass("active");
  $(".balance-scene-canvas .product-bg").eq(index).addClass("active");
  $(".balance-scene-container .content").eq(index).addClass("active");
  var height = $(".balance-scene-container .content").eq(index).height();
  $(".balance-scene-container .text-wrapper").css({"height":height});
  screenSlide({ element: element, slideWidth: 280, currentIndex: index});
});
