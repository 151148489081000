$(document).ready(function () {
    const width = $('.customer-case').width();
    const fontSize = $('html').css('font-size').replace('px', '');
    // let left = -340 * 4 + width / 2;
    // $('.case-container')
    //     .css('left', `${left / fontSize}rem`)
    //     .show();
    // $('.customer-case').on('click', '.prev.active', function () {
    //     left = left + 340;
    //     $('.case-container').css('left', `${left / fontSize}rem`);
    // });
    // $('.customer-case').on('click', '.next.active', function () {
    //     left = left - 340;
    //     $('.case-container').css('left', `${left / fontSize}rem`);
    // });
    let left = 0;
    const minLeft = -340 * 9 + width;
    $('.customer-case').on('click', '.prev.active', function () {
        left += 340;
        if (left >= 0) {
            left = 0;
            $('.prev').removeClass('active');
        } else {
            $('.next').addClass('active');
        }
        $('.case-container').css('left', `${left / fontSize}rem`);
    });
    $('.customer-case').on('click', '.next.active', function () {
        left -= 340;
        if (left <= minLeft) {
            left = minLeft;
            $('.next').removeClass('active');
        } else {
            $('.prev').addClass('active');
        }
        $('.case-container').css('left', `${left / fontSize}rem`);
    });
});
