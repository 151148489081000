$(document).ready(function () {
    // 企业接入支付的难点
    $('.difficult-box > div').mouseenter(function () {
        $(this).find('h4').animate({marginTop: 10, lineHeight: 25});
        $(this).find('.difficult-desc').fadeIn();
    }).mouseleave(function () {
        $(this).find('h4').animate({marginTop: 0, lineHeight: 160});
        $(this).find('.difficult-desc').fadeOut();
    });


    // 计算 首页的图片大小 -- 保证在 video 1 加载完成后计算
    // setTimeout(function () {
        // $('.pc-video .video1 > .img').one('load', function () {
        //
        //     console.log($('.pc-video .video1 > .img').height())
        //
        //     $('.pc-video .video2 > .img,.pc-video .video3 > .img').height(($('.pc-video .video1 > .img').height()) / 2);
        //
        // }).each(function () {
        //     // 防止莫名其妙的bug, 手动触发一次
        //     this.complete && $(this).load();
        // });
    // }, 1000);


    // 移动端播放视频
    // $('.mobile-video > div').on('click', function () {
    //     var $Elem = $(this).find('video');
    //
    //     $Elem.show().siblings().hide();
    //     $Elem[0].play();
    // });


    $('.stickers-box').length > 0 && $('.stickers-box').seamlessRolling({speed: 0.5});


    // 监听模态框
    $('#video1Modal, #video2Modal, #video3Modal').bind('closed.zf.reveal', function () {
        $(this).find('video')[0].pause();
    });

    // 底部工具栏事件
    $('.bottom-tools > div:not(:last-child)').on('click', function () {
        window.location.href = $(this).data('url');
    });

    $('.bottom-tools > div:last-child').hover(function () {
        $(this).css({padding: 0}).find('.no-hover').hide().siblings().show();
    }, function () {
        $(this).css({padding: '35px 0 28px 0'}).find('.had-hover').hide().siblings().show();
    })
});