// var userAgent = navigator.userAgent;
// if (userAgent.indexOf("Firefox") > -1) {
//     var y1 = '-235', y2 = '-469', y3 = '-705', y4 = '-960';
// } else {
//     var y1 = '-235', y2 = '-479', y3 = '-715', y4 = '-960';
// }

// 由于 新版本 firefox 已经跟 chrome 表现一致，所以此处不再做兼容处理
var y1 = '0', y2 = '-480', y3 = '-960', y4 = '-1440';

/**
 * 控制下划线的显示和隐藏
 *
 * @param {*} next
 * @param {*} last
 */
function switchSlideLine(next, last) {
    $('#slideLine' + next).css({ opacity: 1 }).find('.active-line').animate({ width: '100%' }, 2000);
    $('#slideLine' + last).css({ opacity: 0.75 }).find('.active-line').css({ width: 0 });

    // 移动端控制
    $('.phone-title').eq(next - 1).show().siblings().hide();
}

function slideScene() {
    var lineBoxes = $('.line-boxes');
    var sceneView = $('.scene-viewes');
    var heroSceneText = $('.hero-scene-text');
    var bg_hand = $('.scene-slider-wrap .iphone-hand-bg');
    var phone = $('#iphone6');
    var topbar = $('#iphone6 .topbar');
    var camera = $('#iphone6 .camera');
    var speaker = $('#iphone6 .speaker');
    var speakerBefore = $('#iphone6 .speaker-before');
    var homeButton = $('#iphone6 .home');

    var tl = new TimelineMax({ yoyo: false, repeat: -1 });

    $('.fullheight-column-align').hover(function () {
        tl.pause();
    }, function () {
        tl.play();
    });


    // x 为 line box 偏移量， xc 为 sceneView 偏移量
    var x1 = '-772', xc1 = '-166',
        x2 = '-1213', xc2 = '-590',
        x3 = '-1496', xc3 = '-1016',
        x4 = '-1678', xc4 = '-1182';


    tl.add(function () {
        switchSlideLine(1, 4);
    });

    // switch to desktop
    tl.add([
        TweenLite.to(lineBoxes, 1, { opacity: 1, x: x1, ease: Power3.easeInOut }),
        TweenLite.to(sceneView, 1, { y: y2, ease: Power3.easeInOut }),
        TweenLite.to(heroSceneText, 1, { y: y2, ease: Power3.easeInOut }),
        // TweenLite.to(phone, 1, {
        //     width: '423',
        //     height: '327',
        //     top: '18',
        //     left: '-106',
        //     padding: '34px 0 20px 0',
        //     backgroundColor: '#ffffff',
        //     borderTopLeftRadius: '13px',
        //     borderTopRightRadius: '13px',
        //     borderBottomLeftRadius: '0px',
        //     borderBottomRightRadius: '0px',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(topbar, 1, {
        //     backgroundColor: '#f5f5f5',
        //     borderTopLeftRadius: '13px',
        //     borderTopRightRadius: '13px'
        // }),
        // TweenLite.to(speakerBefore, 1, {
        //     top: '13',
        //     left: '5%',
        //     width: '12px',
        //     height: '12px',
        //     backgroundColor: 'rgba(216,216,216,1)',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(speaker, 1, {
        //     top: '13',
        //     left: '9%',
        //     width: '12px',
        //     height: '12px',
        //     borderRadius: '50%',
        //     backgroundColor: 'rgba(216,216,216,1)',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(camera, 1, {
        //     top: '13',
        //     left: '13%',
        //     width: '12px',
        //     height: '12px',
        //     borderRadius: '50%',
        //     backgroundColor: 'rgba(216,216,216,1)',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(bg_hand, 0.8, {
        //     scale: 0.9,
        //     opacity: 0
        // }),
        // TweenLite.to(homeButton, 0.8, {
        //     scale: 0.5,
        //     opacity: 0
        // })
    ], "+=2");


    tl.add(function () {
        switchSlideLine(2, 1);
    });

    tl.add([
        TweenLite.to(lineBoxes, 1, { x: x2, ease: Power3.easeInOut }),
        TweenLite.to(sceneView, 1, { y: y3, ease: Power3.easeInOut }),
        TweenLite.to(heroSceneText, 1, { y: y3, ease: Power3.easeInOut }),
    ], "+=2");

    tl.add(function () {
        switchSlideLine(3, 2);
    });

    // switch to iphone
    tl.add([
        TweenLite.to(lineBoxes, 1, { x: x3, ease: Power3.easeInOut }),
        TweenLite.to(sceneView, 1, { y: y4, ease: Power3.easeInOut }),
        TweenLite.to(heroSceneText, 1, { y: y4, ease: Power3.easeInOut }),
        // TweenLite.to(phone, 1, {
        //     width: '182',
        //     height: '380',
        //     left: '44',
        //     top: '8',
        //     borderRadius: '25px',
        //     padding: '44px 8px 40px 8px',
        //     backgroundColor: '#fcfcfc',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(topbar, 1, {
        //     backgroundColor: '#fcfcfc',
        //     borderTopLeftRadius: '25px',
        //     borderTopRightRadius: '25px',
        // }),
        // TweenLite.to(speakerBefore, 1, {
        //     top: '18',
        //     left: '33%',
        //     width: '6px',
        //     height: '6px',
        //     backgroundColor: 'rgba(39,38,38,1)',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(speaker, 1, {
        //     top: '20',
        //     left: '50%',
        //     width: '28px',
        //     height: '3px',
        //     borderRadius: '4px',
        //     backgroundColor: 'rgba(29,28,28,1)',
        //     ease: Power3.easeInOut
        // }),
        // TweenLite.to(camera, 1, {
        //     top: '8',
        //     left: '50%',
        //     width: '4px',
        //     height: '4px',
        //     borderRadius: '50%',
        //     backgroundColor: 'rgba(29,28,28,1)',
        //     ease: Power3.easeInOut
        // }),

        // TweenLite.to(homeButton, 1.7, { scale: 1, opacity: 1 })
    ], "+=2");

    tl.add(function () {
        switchSlideLine(4, 3);
    });

    tl.add([
        TweenLite.to(lineBoxes, 1, { x: x4, ease: Power3.easeInOut }),
        TweenLite.to(sceneView, 1, { y: y4, ease: Power3.easeInOut }),
        TweenLite.to(heroSceneText, 1, { y: y4, ease: Power3.easeInOut }),
    ], "+=2");


    // tl.to(bg_hand, 0.5, { scale: 1, opacity: 1 }, "-=1.2");
    tl.timeScale(1);
}

function resizeFrame() {
    var lineBoxWapWidth = $('.line-box-wrap').width();
    var lineBoxes = $('.line-boxes');
    var initLeftOfPhoneHand = 295, initLeftOfLineBox = 246;
    var currentLeftOfPhoneHand = Math.ceil(lineBoxWapWidth / 2);
    var moveLeft = initLeftOfPhoneHand - currentLeftOfPhoneHand;
    var currentLeftOfLineBox = -(initLeftOfLineBox + moveLeft);
    TweenLite.to(lineBoxes, 0.1, { x: currentLeftOfLineBox, ease: Power3.easeInOut })
}


function slideViewbox() {
    var viewBoxes = $('#pingerOverview .views');
    var tl = new TimelineMax({ yoyo: false, repeat: -1 });
    tl.to(viewBoxes, 1, { x: "-25%", ease: Power3.easeInOut }, 3)
        .to(viewBoxes, 1, { x: "-50%", ease: Power3.easeInOut }, 8)
        .to(viewBoxes, 1, { x: "-75%", ease: Power3.easeInOut }, 13)
}
