$(document).ready(function () {
    var path = window.location.href;


    // 仅在跨境支付页面中执行
    if (path.indexOf('cross_border') > -1) {
        // top contact-us
        $('.cb-product-box').hover(function () {
            $(".cb-product-box").removeClass('active');
            $(this).addClass('active');

            var target = $(this).data('t');

            $('.cb-info').addClass('hide');
            $('.cb-'+ target +'-info').removeClass('hide');
        });

        let $yinlian = $('.cb-product-crossborder-content-yinlian');
        let $weixin = $('.cb-product-crossborder-content-weixin');
        let $zhifubao = $('.cb-product-crossborder-content-zhifubao');
        let $visa = $('.cb-product-crossborder-content-visa');
        let $dc = $('.cb-product-crossborder-content-dc');
        let $paypal = $('.cb-product-crossborder-content-paypal');

        function crossBorder() {
            $yinlian.animate({opacity: '0.2'}, 3000, 'linear', () => {
                $yinlian.animate({opacity: '1'}, 3000, 'linear');
            });
            $visa.animate({opacity: '0.2'}, 3000, 'linear', () => {
                $visa.animate({opacity: '1'}, 3000, 'linear');
            });
            setTimeout(() => {
                $weixin.animate({opacity: '0.2'}, 3000, 'linear', () => {
                    $weixin.animate({opacity: '1'}, 3000, 'linear');
                });
                $dc.animate({opacity: '0.2'}, 3000, 'linear', () => {
                    $dc.animate({opacity: '1'}, 3000, 'linear');
                });
            }, 3000);
            setTimeout(() => {
                $zhifubao.animate({opacity: '0.2'}, 3000, 'linear', () => {
                    $zhifubao.animate({opacity: '1'}, 3000, 'linear');
                });
                $paypal.animate({opacity: '0.2'}, 3000, 'linear', () => {
                    $paypal.animate({opacity: '1'}, 3000, 'linear');
                });
            }, 6000);
        }

        crossBorder();
        setInterval(() => {
            crossBorder();
        }, 9000)
    }
});