// 收款的方法
var openProductsModal = function (url) {
    window.location.href = `/scenes/${url}`;
};

var openWiki = function (url) {
    window.location.href = `/wiki/${url}`;
};

$(function () {
    // 绑定事件
    $('.control-box.account > span').on('click', function () {
        $(this).removeClass('unactive-control').siblings().addClass('unactive-control');

        // 是否是用户端
        var isClient = $('#client').hasClass('unactive-control');

        var itemName = $('#account-switch-one .box-active').data('img');


        // 改变图片展示
        if (isClient) {
            $('#' + itemName + '-client').fadeIn(1000).siblings().fadeOut();

            $('#clientBox').fadeIn(1000);
            $('#serverBox').hide();
        } else {
            $('#' + itemName + '-server').fadeIn(1000).siblings().fadeOut();

            $('#serverBox').fadeIn(1000);
            $('#clientBox').hide();
        }

    });

    // 充值提现等切换
    $('#account-switch-one > div').on('click', function () {
        $(this).addClass('box-active').siblings().removeClass('box-active');

        // 点击的item name
        var itemName = $(this).data('img');

        // 是否是用户端
        var isClient = $('#client').hasClass('unactive-control');

        // 切换图片
        if (isClient === true) {
            $('#' + itemName + '-client').fadeIn(1000).siblings().fadeOut();
        } else {
            $('#' + itemName + '-server').fadeIn(1000).siblings().fadeOut();
        }
    });

    $('.pc-switch > div').on('click', function () {
        $(this).addClass('box-active').siblings().removeClass('box-active');

        // 点击的item name
        var itemName = $(this).data('img');

        // 切换图片
        $('#' + itemName).fadeIn(1000).siblings().fadeOut();
    });


    $('.coupon > div').on('click', function () {
        $(this).addClass('box-active').siblings().removeClass('box-active');

        // 点击的item name
        var itemName = $(this).data('img');

        $('#' + itemName).fadeIn(1000).siblings().fadeOut();

    });

    $('#scenSlide').splide();
    $('#scenSlide1').splide({num: 3});


    //  platform 页面
    $('.control-box.platform > span').on('click', function () {
        $(this).removeClass('unactive-control').siblings().addClass('unactive-control');

        // 点击的item name
        var itemName = $(this).data('img');

        // 切换图片
        $('#' + itemName).fadeIn(1000).siblings().hide();
    });

    $('#scenSlidePlatform1').splide({num: 2});
    $('#scenSlidePlatform2').splide({num: 2});
    $('#scenSlidePlatform3').splide({num: 2});
    $('#scenSlidePlatform4').splide({num: 3});

});