$('.nav-bar li').click((e) => {
    $('.nav-bar li').removeClass('active');
    $(e.currentTarget).addClass('active');
    const type = $(e.currentTarget).attr('data-type');
    if (type == 1) {
        $('.scroll-bar').css('left', '0');
    } else {
        $('.scroll-bar').css('left', '-100%');
    }
});

$('.information-item').click((e) => {
    $('.information-item').removeClass('active');
    $(e.currentTarget).addClass('active');
    const type = $(e.currentTarget).attr('data-type');
    initPageData(type, 1);
});
const infos = [
    {
        title: '【荣誉】简米 | Ping++ 荣膺 “产业数字化技术赋能先锋企业”',
        desc: '近日，由江苏省商务厅、南京市人民政府的指导，南京市商务局、南京市鼓楼区人民政府主办，托比网、亿邦动力、南京市电子商务协会',
        type: '公司动态',
        date: '2023-11-20',
        href: '/news/10019.html',
        img: '../assets/img/information/pioneer.png',
    },
    {
        title: '【荣誉】简米 | Ping++ 荣获产业互联与数字经济大会双重奖项',
        desc: '8月23-25日，由中信联产业互联网工委会指导，山东省工业和信息化厅、日照市人民政府支持，东港区人民政府、日照市工业和信息化局、B2B内参主办',
        type: '公司动态',
        date: '2023-09-01',
        href: '/news/10018.html',
        img: '../assets/img/information/double.png',
    },
    {
        title: '【荣誉】简米 | Ping++ 获2023年上海市“专精特新中小企业”认定',
        desc: '近日，上海市经济和信息化委员会公布了2023年上海市专精特新中小企业名单（第一批），上海简米网络科技有限公司（简米 | Ping++）凭',
        type: '公司动态',
        date: '2023-07-03',
        href: '/news/10017.html',
        img: '../assets/img/information/mastery.png',
    },
    {
        title: '【荣誉】Ping++ 简米 | Ping++ 被评选为“优秀开放银行服务商”',
        desc: '2022年5月31日，由万联网主办的 “CSCFIS 第九届中国供应链金融创新高峰论坛” 隆重召开。',
        type: '公司动态',
        date: '2022-05-31',
        href: '/news/10016.html',
        img: '../assets/img/information/openness.png',
    },
    {
        title: 'Ping++ 成功备案中国支付清算协会聚合支付技术服务机构',
        desc: '11月27日，Ping++（上海简米网络科技有限公司）进入中国支付清算协会第三批收单外包服务机构名单',
        type: '公司动态',
        date: '2021-06-29',
        href: '/news/10015.html',
        img: '../assets/img/information/pcac.png',
    },
    {
        title: '【喜讯】Ping++ 加入上海市电子商务行业协会（SECA）',
        desc: 'Ping++（上海简米网络科技）加入上海市电子商务行业协会，以综合专业的支付解决方案为电子商务市场的发展添砖加瓦，共同助力电子商务企业数字化转型。',
        type: '公司动态',
        date: '2021-06-01',
        href: '/news/10014.html',
        img: '../assets/img/information/seca.png',
    },
    {
        title: '【喜讯】Ping++ 正式成为中国产业互联网发展联盟（IDAC）会员单位',
        desc: 'Ping++（上海简米网络科技有限公司）正式成为中国产业互联网发展联盟（IDAC）会员单位。',
        type: '公司动态',
        date: '2021-05-28',
        href: '/news/10013.html',
        img: '../assets/img/information/idac.png',
    },
    {
        title: '分账系统帮助电商平台节省约 20 万成本',
        desc: '2020年，拼多多、字节跳动、抖音、快手等多家巨头企业收购相关支付牌照，打造自己的支付平台，完成商业闭环。',
        type: '行业资讯',
        date: '2021-02-05',
        href: '/news/10012.html',
        img: '../assets/img/information/e-commerce.png',
    },
    {
        title: '从 0 到1，这才是 B2B 分账系统该有的样子',
        desc: '很多 B2B 企业遇到分账问题时才会开始搭建分账系统，如每月末按照 20% 的比例分账给入驻商家、32% 的比例分给经销商。',
        type: '行业资讯',
        date: '2021-01-29',
        href: '/news/10011.html',
        img: '../assets/img/information/0to1.png',
    },
    {
        title: '1 年至少省 28 万，医疗 SaaS 行业的分账系统是如何实现的？',
        desc: '2017年，Ping++ 服务上海领健信息技术有限公司——国内领先的医疗 SaaS 平台，帮助领健接入聚合支付。',
        type: '行业资讯',
        date: '2021-01-28',
        href: '/news/10010.html',
        img: '../assets/img/information/treat.png',
    },
    {
        title: '合规分账，企业如何避免踩坑？',
        desc: '从电商平台、B2B供应链、垂直行业SaaS、物流服务到连锁加盟企业，各个行业都存在不同场景的分账需求。',
        type: '行业资讯',
        date: '2021-01-22',
        href: '/news/10009.html',
        img: '../assets/img/information/compliance.png',
    },
    {
        title: '产业互联网：B2B 交易支付为何这么难？',
        desc: '“一旦涉及交易支付，就是让我们头疼的事情，尤其是 B2B 交易支付。企业与企业间的支付审核严、流程复杂、单笔结算资金数额大、对账难、效率低下，一笔交易数个坑。',
        type: '行业资讯',
        date: '2021-01-08',
        href: '/news/10008.html',
        img: '../assets/img/information/b2b.png',
    },
    {
        title: '从1.0到3.0，简米分账系统的历练与成长',
        desc: '日日行，不怕千万里；常常做，不怕千万事。当做一件事情已经变得稀疏平常的时候，它就有坚持下去的意义',
        type: '公司动态',
        date: '2020-12-12',
        href: '/news/10007.html',
        img: '../assets/img/information/development.png',
    },
    {
        title: '一文详解平台型的银行存管业务',
        desc: '产业互联网通过搭建产业聚集的平台，链接上下游企业，以共用平台基础设施为联接创造更多的价值。',
        type: '行业资讯',
        date: '2020-11-02',
        href: '/news/10005.html',
        img: '../assets/img/information/depository.png',
    },
    {
        title: '4 招支付方案助力产业互联网平台走得更远',
        desc: '作为一种新的经济形态，产业互联网的发展如火如荼。它被认为是中国未来10年最大的商业机会之一，产业路由器 B2F 的商业模式也被称为中国未来10年最可能领先的商业模型。',
        type: '行业资讯',
        date: '2020-11-02',
        href: '/news/10006.html',
        img: '../assets/img/information/4moves.png',
    },
    {
        title: 'Ping++ 上榜 2020 年中国企业服务领域高成长企业 TOP 100',
        desc: '7 月 30 日，由企名科技 & 24 新声举办的 2020 年中国企业服务领域高成长企业 TOP 100 和 2020 年',
        type: '公司动态',
        date: '2020-07-31',
        href: '/news/10004.html',
        img: '../assets/img/information/top100.png',
    },
    {
        title: 'Ping++ 荣获 CPEA 2020 优秀支付服务商「金藤奖」',
        desc: '近日， CPEA2020优秀支付服务商「金藤奖」获奖名单公示。Ping++（上海简米网络科技有限公司）在',
        type: '公司动态',
        date: '2020-07-07',
        href: '/news/10003.html',
        img: '../assets/img/information/cpea.png',
    },
    {
        title: 'Ping++ 入选 CB Insights 70 家中国企业服务竞争力榜单',
        desc: '5 月 28 日，全球知名的市场数据研究平台 CB Insights 首次发布中国企业服务榜单， Ping++ 以“ 聚合支付',
        type: '公司动态',
        date: '2020-05-30',
        href: '/news/10002.html',
        img: '../assets/img/information/be-selected.png',
    },
    {
        title: 'Ping++获 PCI DSS认证 领跑聚合支付数据安全',
        desc: '2016.10.10，支付系统服务商 Ping++正式对外宣布已通过 PCI DSS(Payment Card Industry Data Security Standard) V3.1 版本的合规评估',
        type: '公司动态',
        date: '2018-02-27',
        href: '/news/10001.html',
        img: '../assets/img/information/picdss.png',
    },
];

let pageCount = 0;
function initPageData(type, page) {
    let arr;
    if (type == 1) {
        arr = infos.filter((e) => e.type == '行业资讯');
    } else if (type == 2) {
        arr = infos.filter((e) => e.type == '公司动态');
    } else {
        arr = infos;
    }
    pageCount = Math.ceil(arr.length / 12);
    $('.information-content .total').html(pageCount);
    let liHtml = '';
    for (let i = 1; i <= pageCount; i++) {
        liHtml += `<li class="page-item ${i == page ? 'active' : ''}" data-page="${i}">${i}</li>`;
    }
    $('.information-content .pages').html(liHtml);
    const pageData = arr.slice((page - 1) * 12, page * 12);
    let listHtml = '';
    pageData.forEach((e) => {
        listHtml += `<li class="info-item">
                <a href="${e.href}" target="_blank" title="${e.title}">
                    <div class="white-bg">
                        <img class="info-img" src="${e.img}" />
                    </div>
                    <div class="info-content">
                        <p class="info-title">${e.title}</p>
                        <p class="info-desc" style="-webkit-box-orient: vertical;">${e.desc}</p>
                        <div class="class-date">
                            <span class="class">${e.type}</span>
                            <span class="date">${e.date}</span>
                        </div>
                    </div>
                </a>
            </li>
            `;
    });
    $('.info-list').html(listHtml);
    // 滚到顶部
    $('html,body').animate({ scrollTop: 0 }, 300);
    if (page >= pageCount) {
        $('.information-content .next').removeClass('active');
    } else {
        $('.information-content .next').addClass('active');
    }
    if (page <= 1) {
        $('.information-content .prev').removeClass('active');
    } else {
        $('.information-content .prev').addClass('active');
    }

    $('.page-item').removeClass('active');
    $('.page-item')
        .eq(page - 1)
        .addClass('active');
}

initPageData('0', 1);

$('.pages').on('click', '.page-item', (e) => {
    const page = $(e.currentTarget).attr('data-page');
    initPageData($('.information-item.active').attr('data-type'), page);
});
$('.information-content .prev').click(() => {
    const page = $('.pages .active').attr('data-page') * 1 - 1;
    if (page < 1) return;

    initPageData($('.information-item.active').attr('data-type'), page);
});
$('.information-content .next').click(() => {
    const page = $('.pages .active').attr('data-page') * 1 + 1;
    if (page > pageCount) return;

    initPageData($('.information-item.active').attr('data-type'), page);
});
// 输入框回车事件
$('.information-content input').on('keydown', (e) => {
    if (e.keyCode == 13) {
        const page = $('.information-content input').val();
        if (page > pageCount || page < 1) return;
        initPageData($('.information-item.active').attr('data-type'), page);
    }
});
