$(document).ready(function () {
    const path = window.location.href;

    // 仅在定价页面中执行
    if (path.indexOf('pricing') > -1) {
        $(document).scroll(function () {
            const scroll = $(document).scrollTop();
            let priceH = $('.price-box').height();
            let tableHeader = $('.body-info-header').height();
            let tableH = $('.pricing-body').height();
            let tableW = $('.body-info').width();
            $('.price-table-box .fixed-title').css({ width: tableW });
            if (scroll > priceH + tableHeader && scroll < priceH + tableH) {
                $('.price-table-title-float').addClass('fixed-title');
            }

            if (scroll > priceH + tableH || scroll <= priceH + tableHeader) {
                $('.price-table-title-float').removeClass('fixed-title');
            }
        });
        $('.expand-more').on('click', function () {
            $(this).siblings('.table-body-m').css({ height: 'auto', overflow: 'auto' });
            $(this).hide().siblings('.pack-up').show();
        });
        $('.pack-up').on('click', function () {
            $(this).siblings('.table-body-m').removeAttr('style');
            $(this).hide().siblings('.expand-more').show();
        });
        let isExpand = true;
        $('.expend').on('click', function () {
            if (isExpand) {
                isExpand = false;
                $('.price-table-box').addClass('is-close');
                $('.expend img').css({ transform: 'rotate(180deg)' });
            } else {
                isExpand = true;
                $('.price-table-box').removeClass('is-close');
                $('.expend img').css({ transform: 'rotate(0deg)' });
            }
        });
    }
});
