var humanReadableLangs = {
    php: 'PHP',
    javascript: 'Node.js',
    go: 'Go',
    json: 'JSON',
    python: 'Python',
    cs: 'C#',
    objectivec: 'Objective-C',
    ruby: 'Ruby',
    java: 'Java'
};

var ignoredLangs = [
    'json', 'xml', 'html'
];

var populateLanguageChannelSelector = function () {
    var langs = [];
    var channels = [];

    $("code[class^='language-']").each(function () {
        langs.push($(this).attr("class").toString().toLowerCase().slice(9));
    });

    $('.code-filter').each(function () {
        var channel = $(this).attr('class').toString().toLowerCase().split(' ')[1];
        channels.push(channel);
    });

    langs = _.difference(_.uniq(langs), ignoredLangs);
    channels = _.uniq(channels);

    if (langs.length > 1) {
        _.each(langs, function (lang) {
            $('#language-select').append($('<li><a onclick="filterByLanguage(\'' + lang + '\')">' + humanReadableLangs[lang] + '</a></li>'));
        });
        $('.language-select-item > a').text(langs[0]);
        $('.language-select-item').addClass('on');
        filterByLanguage(langs[0]);
    } else {
        $('.language-select-item').remove();
    }

    if (channels.length > 1) {
        _.each(channels, function (channel) {
            $('#channel-select').append($('<li><a onclick="filterByChannel(\'' + channel + '\')">' + channel + '</a></li>'));
        });
        $('.channel-select-item > a').text(channels[0]);
        $('.channel-select-item').addClass('on');
        filterByChannel(channels[0]);
    } else {
        $('.channel-select-item').remove();
    }
}

var filterByLanguage = function (language) {
    var filterIgnoredLangs = ignoredLangs.slice(0);
    filterIgnoredLangs.push(language);

    $("code[class^='language-']").each(function () {
        var l = $(this).attr("class").split(' ')[0].toString().toLowerCase().slice(9);
        if (_.contains(filterIgnoredLangs, l)) {
            $(this).removeClass("hide");
        } else {
            $(this).addClass("hide");
        }
    });

    $(".filter-by-language").each(function () {
        var l = $(this).attr("data-language");
        if (_.contains(filterIgnoredLangs, l)) {
            $(this).removeClass("hide");
        } else {
            $(this).addClass("hide");
        }
    })

    $('.language-select-item > a').text(humanReadableLangs[language]);
}

var filterByChannel = function (channel) {
    $('.code-filter').each(function () {
        var c = $(this).attr('class').toString().toLowerCase().split(' ')[1];
        if (c !== channel) {
            $(this).addClass('hide');
        } else {
            $(this).removeClass('hide');
        }
    });

    $('.channel-select-item > a').text(channel);
}


if (window.location.href.indexOf('docs') > -1) {
    var urlSearch = window.location.search;
    var urlHref = window.location.href;

    if (urlSearch !== '') {
        var params = urlSearch.split('='),
            key = params[0].split('?')[1],
            isOpen = params[1];

        if (isOpen == 'true') {
            $(`#${key}`).nextUntil($(`[data-index!="${key}"]`)).show();

            $('.thirdcategory').hide();

            // todo 配置3
            if(urlHref.indexOf('overview') > -1){
                switch (key) {
                    case 'basic_transaction':
                        if (urlHref.indexOf('charge_refunds') > -1) {
                            $('.charge_refunds').show();
                        }
                        break;
                    case 'account':
                        if (urlHref.indexOf('balances') > -1) {
                            $('.balances').show();
                        } else if (urlHref.indexOf('coupons') > -1) {
                            $('.coupons').show();
                        } else if (urlHref.indexOf('orders') > -1) {
                            $('.orders').show();
                        } else if (urlHref.indexOf('user_accounts') > -1) {
                            $('.user_accounts').show();
                        }
                        break;
                    case 'platform':
                        if (urlHref.indexOf('hierarchies') > -1) {
                            $('.hierarchies').show();
                        } else if (urlHref.indexOf('orders') > -1) {
                            $('.orders').show();
                        } else if (urlHref.indexOf('royalties') > -1) {
                            $('.royalties').show();
                        } else if (urlHref.indexOf('user_accounts') > -1) {
                            $('.user_accounts').show();
                        }
                        break;
                }
            } else if (urlHref.indexOf('server') > -1) {
                switch (key) {
                    case 'basic_transaction':
                        if (urlHref.indexOf('charge_refunds') > -1) {
                            $('.charge_refunds').show();
                        }
                        break;
                    case 'account':
                        if (urlHref.indexOf('balances') > -1) {
                            $('.balances').show();
                        } else if (urlHref.indexOf('coupons') > -1) {
                            $('.coupons').show();
                        } else if (urlHref.indexOf('orders') > -1) {
                            $('.orders').show();
                        } else if (urlHref.indexOf('user_accounts') > -1) {
                            $('.user_accounts').show();
                        }
                        break;
                    case 'platform':
                        if (urlHref.indexOf('hierarchies') > -1) {
                            $('.hierarchies').show();
                        } else if (urlHref.indexOf('orders') > -1) {
                            $('.orders').show();
                        } else if (urlHref.indexOf('royalties') > -1) {
                            $('.royalties').show();
                        } else if (urlHref.indexOf('user_accounts') > -1) {
                            $('.user_accounts').show();
                        }
                        break;
                }
            }

        }
    }

// disabled 的状态菜单张开
    $('li.subcategory.disabled.is-index').each(function (index, el) {
        $(el).nextUntil($(':not(.subcategory)')).show();
    });

// 绑定菜单事件
    $('.side-bar li').on('click', function () {
        var href = $(this).data('href'),
            pathName = window.location.pathname;

        // 如果是可展开项
        if ($(this).hasClass('subcategory') && $(this).hasClass('is-index')) {
            if (href === pathName) {
                $(this).nextUntil($('[data-index]!=' + $(this).data('index'))).show();
            } else {
                window.location.href = href + '?' + $(this).attr('id') + '=true';
            }
        } else {
            // 往前查找最近一个 可展开项
            var $firstEl = $(this).prevAll().filter('li.subcategory.is-index'),
                params = '';

            // 如果此元素的 id 与 当前点击元素的 索引相同, 则证明是父子关系
            if ($firstEl.attr('id') === $(this).data('index')) {
                params = '?' + $(this).data('index') + '=true';
            }

            window.location.href = href + params;
        }
    });

}