$(document).ready(function () {
    var path = window.location.href;

    // 仅在 推荐大使 页面中执行
    if (path.indexOf('recommend') > -1) {

        // wave animate
        $('#img1').seamlessRolling({speed: 3.5});
        $('#img2').seamlessRolling({speed: 2.2});
        $('#img3').seamlessRolling({speed: 1});

    }
});