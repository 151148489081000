$(document).foundation();

// 注册 cookie 操作插件
/*! jquery.cookie v1.4.1 | MIT */
!(function (a) {
    'function' == typeof define && define.amd ? define(['jquery'], a) : 'object' == typeof exports ? a(require('jquery')) : a(jQuery);
})(function (a) {
    function b(a) {
        return h.raw ? a : encodeURIComponent(a);
    }

    function c(a) {
        return h.raw ? a : decodeURIComponent(a);
    }

    function d(a) {
        return b(h.json ? JSON.stringify(a) : String(a));
    }

    function e(a) {
        0 === a.indexOf('"') && (a = a.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\'));
        try {
            return (a = decodeURIComponent(a.replace(g, ' '))), h.json ? JSON.parse(a) : a;
        } catch (b) {}
    }

    function f(b, c) {
        var d = h.raw ? b : e(b);
        return a.isFunction(c) ? c(d) : d;
    }

    var g = /\+/g,
        h = (a.cookie = function (e, g, i) {
            if (void 0 !== g && !a.isFunction(g)) {
                if (((i = a.extend({}, h.defaults, i)), 'number' == typeof i.expires)) {
                    var j = i.expires,
                        k = (i.expires = new Date());
                    k.setTime(+k + 864e5 * j);
                }
                return (document.cookie = [
                    b(e),
                    '=',
                    d(g),
                    i.expires ? '; expires=' + i.expires.toUTCString() : '',
                    i.path ? '; path=' + i.path : '',
                    i.domain ? '; domain=' + i.domain : '',
                    i.secure ? '; secure' : '',
                ].join(''));
            }
            for (var l = e ? void 0 : {}, m = document.cookie ? document.cookie.split('; ') : [], n = 0, o = m.length; o > n; n++) {
                var p = m[n].split('='),
                    q = c(p.shift()),
                    r = p.join('=');
                if (e && e === q) {
                    l = f(r, g);
                    break;
                }
                e || void 0 === (r = f(r)) || (l[q] = r);
            }
            return l;
        });
    (h.defaults = {}),
        (a.removeCookie = function (b, c) {
            return void 0 === a.cookie(b) ? !1 : (a.cookie(b, '', a.extend({}, c, { expires: -1 })), !a.cookie(b));
        });
});

// 注册 url params 解析插件
$.extend({
    //拉取名为 name 的 cookie 值
    getCookie: function (name) {
        var ret = document.cookie.match(new RegExp('(?:^|;\\s)' + name + '=(.*?)(?:;\\s|$)'));
        return ret ? ret[1] : '';
    },

    //设置 cookie
    setCookie: function (name, value, expires, domain, path, secure) {
        var path = '/';
        var domain = domain || 'pingxx.com';

        var cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

        expires && expires instanceof Date && (cookieText += '; expires=' + expires.toGMTString());
        path && (cookieText += '; path=' + path);
        domain && (cookieText += '; domain=' + domain);
        secure && (cookieText += '; secure');

        document.cookie = cookieText;

        return this;
    },

    getQueryParameters: function (str) {
        return (str || document.location.search)
            .replace(/(^\?)/, '')
            .split('&')
            .map(
                function (n) {
                    return (n = n.split('=')), (this[n[0]] = n[1]), this;
                }.bind({})
            )[0];
    },

    randomString: function (len) {
        var len = len || 32;
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var maxPos = chars.length;
        var pwd = '';

        for (var i = 0; i < len; i++) {
            pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }

        return pwd;
    },

    initReport: function () {
        var href = location.href,
            search = location.search || '',
            bd_vid = this.getQueryParameters().bd_vid || '',
            from = this.getQueryParameters().from || 2,
            desc = this.getQueryParameters().desc || '',
            sub_from = this.getQueryParameters().sub_from || '',
            source = this.getQueryParameters().source || '',
            plan = decodeURI(this.getQueryParameters().plan || ''),
            unit = decodeURI(this.getQueryParameters().unit || ''),
            keyword = decodeURI(this.getQueryParameters().keyword || ''),
            device = this.getQueryParameters().device || '',
            sem_track = `?source=${source}&plan=${plan}&unit=${unit}&keyword=${keyword}&device=${device}&bd_vid=${bd_vid}`,
            puid = this.getCookie('puid') || '';

        var eqid = this.getQueryParameters(document.referrer.split('?')[1]).eqid || '';
        var date = new Date(new Date().getTime() + 10 * 365 * 86400000);
        var domain = location.hostname.replace('www.', '') || 'pingxx.com';

        search !== '' && this.setCookie('search', search, date, domain);

        this.setCookie('href', href, date, domain).setCookie('bd_vid', encodeURIComponent(bd_vid), date, domain).setCookie('eqid', eqid, date, domain);

        // 检查是否存在 puid 的 cookie 如果已有，doNothing, 否则先生成cookie 再上报
        if (!puid) {
            if (/pingxx/.test(location.href)) {
                puid = this.randomString();

                this.setCookie('puid', puid, date, domain).setCookie('from', from, date, domain).setCookie('desc', desc, date, domain).setCookie('sub_from', sub_from, date, domain);
            }
        }

        if (/pingxx/.test(location.href)) {
            if (source || plan !== '' || unit !== '' || keyword !== '' || device) {
                this.setCookie('_sem_track', sem_track, date, domain);
            }
        }
    },
});

// 注册无缝滚动插件
$.fn.seamlessRolling = function (option) {
    function mixinsAnimationFrame(type) {
        if (type === 'request') {
            return (
                window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback) {
                    var currTime = +new Date(),
                        delay = Math.max(1000 / 60, 1000 / 60 - (currTime - lastTime));
                    lastTime = currTime + delay;
                    return setTimeout(callback, delay);
                }
            );
        } else {
            return (
                window.cancelAnimationFrame ||
                window.webkitCancelAnimationFrame ||
                window.webkitCancelRequestAnimationFrame ||
                window.mozCancelRequestAnimationFrame ||
                window.msCancelRequestAnimationFrame ||
                clearTimeout
            );
        }
    }

    function seamlessRolling($El, speed) {
        // 目标位置
        var $items = $El,
            target = $items.width();

        // clone 元素
        $items.html($items.html() + $items.html());

        var timer = null,
            scrollX = 0;

        function adAni() {
            timer = nextFrame(function () {
                scrollX += speed;
                if (scrollX >= target) {
                    scrollX = 0;
                }
                $items.scrollLeft(scrollX);
                adAni();
            });
        }

        adAni();
    }

    // 暂时没有处理取消动画的情况, 先留着 cancelFrame 备用
    var lastTime = 0,
        nextFrame = mixinsAnimationFrame('request'),
        cancelFrame = mixinsAnimationFrame();

    var _option = $.extend(
        {
            speed: 2,
        },
        option
    );
    seamlessRolling(this, _option.speed);

    return this;
};

// 此处注册jq 插件 - --- - : 移动端点击横向切换插件
$.fn.splide = function (option) {
    var _option = $.extend(
        {
            num: 4,
        },
        option
    );

    var n = 0,
        _this = this;
    this.find('.arrow-s-r').click(function () {
        if (n == _option.num - 1) {
            n = -1;
        }
        n++;
        switchPic(n, this);
        return n;
    });
    this.find('.arrow-s-l').click(function () {
        if (n == 0) {
            n = _option.num;
        }
        n--;
        switchPic(n, this);
        return n;
    });

    function switchPic(n) {
        var w = 20.6 * n - 1.6;

        _this.find('.tab-outer-s').animate({ left: -w + 'em' }, 1000);
        _this
            .find('.scen-' + n)
            .fadeIn(1000)
            .siblings()
            .fadeOut();
    }
};

// ui logic, animations and transitions
$(document).ready(function () {
    if (navigator.userAgent.match(/MicroMessenger/i)) {
        $('body').prepend('<div style=" overflow:hidden; width:0px; height:0; margin:0 auto; position:absolute; top:-800px;"><img src="/assets/img/wxshare.png"></div>');
    }

    $.initReport();

    //for mobile menu
    setMobileNav();

    if ($(window).width() > 760) {
        homepageTween();
        setStickyNavBar();
    }

    cmbIntroAnimate();
    // cue animations
    // animateHero();
    cubeAnimation();
    slideScene();

    // animate hero backgrounds
    setInterval(heroCoinDropAnimation, 500);
    setInterval(countdownDropAnimation, 1500);
    setInterval(uberTitleAnimation, 3000);

    // activate countdown
    $('.countdown-timer').countdown('2016/04/20 18:00:00', function (e) {
        var totalHours = e.offset.totalDays * 24 + e.offset.hours;
        $(this).find('.hours').text(totalHours);
        $(this).find('.minutes').text(e.strftime('%M'));
        $(this).find('.seconds').text(e.strftime('%S'));
    });
    $('.nav-title').on('click', function () {
        if ($(this).siblings('.nav-content').hasClass('expand')) {
            $(this).siblings('.nav-content').removeClass('expand');
        } else {
            $(this).siblings('.nav-content').addClass('expand');
        }
    });

    var path = document.location.pathname.split('/');
    var page = path[path.length - 1];
    if (page.indexOf('html') > 0) {
        page = page.slice(0, -5);
    }
    // if (page === "customers") {
    //     testimonialParallax();
    //     var $testimonialContent = $(".testimonial-content--new");

    //     $testimonialContent.isotope({
    //         itemSelector: ".testimonial-card",
    //         layoutMode: 'vertical',
    //         hiddenStyle: {
    //             opacity: 0
    //         },
    //         visibleStyle: {
    //             opacity: 1
    //         }
    //     });

    //     $('#industryFilters').on('click', 'a', function () {
    //         var filterValue = $(this).attr('data-filter');
    //         $('#industryFilters a').removeClass("active");
    //         $(this).addClass("active");
    //         $testimonialContent.isotope({
    //             filter: filterValue
    //         });
    //     });

    //     $('#productFilters').on('click', 'a', function () {
    //         var filterValue = $(this).attr('data-filter');
    //         $('#productFilters a').removeClass("active");
    //         $(this).addClass("active");
    //         $testimonialContent.isotope({
    //             filter: filterValue
    //         });
    //     });

    //     $("#panel1-label").click(function () {
    //         $("#industryFilters a.all").trigger("click");
    //     });

    //     $("#panel2-label").click(function () {
    //         $("#productFilters a.all").trigger("click");
    //     });

    //     var targets = (document.location.hash).slice(1).split("&");
    //     var type = targets[0],
    //         filter = targets[1];
    //     if (type == "category") {
    //         $("#panel1-label").trigger("click");
    //         $("#industryFilters a." + filter).trigger("click");
    //     } else if (type == "product") {
    //         $("#panel2-label").trigger("click");
    //         $("#productFilters a." + filter).trigger("click");
    //     }
    // } else
    if (page == 'about') {
        if ($(window).width() > 770) {
            setStickyforPositon();
        }
        $('.about-us-next').on('click', function (e) {
            var mainPos = $('.main-pos').attr('id');
            var mainSrc = $('.right-pos').attr('src');
            var imgSize = 3; // 当前只有两张图片+一张位置占用图
            function getPos(pos) {
                if (Number(mainPos) + Number(pos) <= imgSize) {
                    return Number(mainPos) + Number(pos);
                } else {
                    return Math.abs(imgSize - (Number(mainPos) + Number(pos)));
                }
            }
            $('#' + getPos(0))
                .removeClass('main-pos')
                .addClass('back-pos');
            $('#' + getPos(1))
                .removeClass('back-pos')
                .addClass('right-pos');
            $('#' + getPos(2))
                .removeClass('right-pos')
                .addClass('main-pos');
            $('.back-pos').attr('src', mainSrc);
        });
    } else if (page == 'career') {
        slideViewbox();
        var winW = $(window).width();
        var rowCount = Math.ceil(winW / 300) + 2;
        var options = {
            container: $('#polygonContainer'),
            rowCount: rowCount,
        };
        var polygon = new PolygonFactory(options);
        polygon.init().renderPolygon();
        var startX,
            startY,
            lastMoveX = 0,
            lastMoveY = 0,
            maxOffestx = 1200,
            minOffsetY = 2000;
        $('#polygonContainer').on('mouseenter', function (event) {
            startX = event.pageX;
            startY = event.pageY;
        });

        $('#polygonContainer').on('mousemove', function (event) {
            updatePos(event);
        });

        $('#hero .hero-copy').on('mousemove', function (event) {
            updatePos(event);
        });

        function updatePos(event) {
            var xPosition = event.pageX;
            var yPosition = event.pageY;
            var offsetX = xPosition - startX;
            var offsetY = yPosition - startY;
            if (offsetX > maxOffestx) offsetX = maxOffestx;
            if (offsetX > maxOffestx) offsetY = maxOffestx;
            var moveX = -(offsetX * 0.2);
            var moveY = -(offsetY * 0.2);
            var distanceX = moveX - lastMoveX;
            var distanceY = moveY - lastMoveY;
            lastMoveX = moveX;
            lastMoveY = moveY;
            if (distanceX > 60 || distanceY > 60) return;
            window.requestAnimationFrame(function () {
                $('#polygonContainer').css({
                    transform: 'translate(' + moveX + 'px,' + moveY + 'px)',
                });
            });
        }
    } else if (page === 'product' || page === 'products') {
    } else if (page === 'contact') {
        // var targets = (document.location.hash).slice(1);
        // if (targets == "sales") {
        //     document.getElementById('to').getElementsByTagName('option')[1].selected = 'selected'
        // } else if (targets == "customized") {
        //     document.getElementById('to').getElementsByTagName('option')[2].selected = 'selected'
        // }
        // console.log(targets);

        if ($.getQueryParameters().purpose) {
            $('#to option[data-v=' + $.getQueryParameters().purpose + ']').prop('selected', true);
        }
    }

    // Smooth scroll to anchor
    $('.targetLink').on('click', function (e) {
        // prevent default action and bubbling
        e.preventDefault();
        e.stopPropagation();
        // set target to anchor's "href" attribute
        var target = $(this).attr('href');
        // scroll to each target
        $(target).velocity('scroll', {
            duration: 300,
            offset: -40,
            easing: 'linear',
        });
    });

    // Initialize docs ---------

    $('.docs-article-select-toggle').on('click', function (e) {
        $('.docs-article-select').toggleClass('on');
    });

    populateLanguageChannelSelector();

    $('.consult-btn').on('click', function () {
        var w = window.innerWidth - document.body.scrollWidth;
        $('#contact')[0].reset();
        setTimeout(function () {
            $('body').css('padding-right', w);
            $('body').css('overflow', 'hidden');
        }, 400);
        $('#consultSlide').removeClass('down').addClass('on');
        const host = location.host.includes('pingxx') ? 'https://dashboard.pingxx.com' : 'https://dashboard.pinpula.com';
        $.ajax({
            method: 'POST',
            url: host + '/auto/user/market_track_click',
            data: { type: 'contact_click' },
            dataType: 'json',
            crossDomain: true,
            xhrFields: {
                withCredentials: true,
            },
        });
    });

    $('.open-form-btn').on('click', function () {
        var w = window.innerWidth - document.body.scrollWidth;
        $('#contact')[0].reset();
        setTimeout(function () {
            $('body').css('padding-right', w);
            $('body').css('overflow', 'hidden');
        }, 400);
        $('#consultSlide').removeClass('down').addClass('on');
        const host = location.host.includes('pingxx') ? 'https://dashboard.pingxx.com' : 'https://dashboard.pinpula.com';
        $.ajax({
            method: 'POST',
            url: host + '/auto/user/market_track_click',
            data: { type: 'contact_click' },
            dataType: 'json',
            crossDomain: true,
            xhrFields: {
                withCredentials: true,
            },
        });
    });

    $('.pro-close').on('click', function () {
        $('body').css('overflow', 'visible');
        $('body').css('padding-right', '0px');
        $('#consultSlide').removeClass('on').addClass('down');
    });
    $('#pro-success-btn').on('click', function () {
        $('body').css('overflow', 'auto');
        $('#proSuccess').hide();
    });

    Foundation.Abide.defaults.patterns['name'] = /^[\u4e00-\u9fa5|a-zA-Z]{2,10}$/;
    Foundation.Abide.defaults.patterns['mobile'] = /^[0-9]{11}$/;
    Foundation.Abide.defaults.patterns['company'] = /^[\u4e00-\u9fa5|a-zA-Z]{4,20}$/;

    $(document)
        .on('formvalid.zf.abide', function (ev, frm) {
            if (frm.attr('nocontact') !== undefined) return false;

            var data = frm.serialize();

            // 新版联系我们name需要拼接
            if ($('#first_name').val() || $('#last_name').val()) {
                data += '&name=' + $('#last_name').val() + $('#first_name').val();
            }

            const host = location.host.includes('pingxx') ? 'https://dashboard.pingxx.com' : 'https://dashboard.pinpula.com';

            if (!$('#submit').hasClass('disabled')) {
                $('#submit').hide();
                $('#submiting').fadeIn();
                $.ajax({
                    method: 'POST',
                    url: location.host.includes('localhost') ? 'http://ld.local.pinpula.com/crm/notify/official_web' : 'https://t.pingxx.com/crm/notify/official_web',
                    data: JSON.stringify({
                        ucid: Date.now(),
                        clueId: `${Date.now()}`,
                        data: {
                            name: $('#name').val(),
                            mobile: $('#mobile').val(),
                            company: $('#company').val(),
                            business_c: $('#business_c').val(),
                        },
                        source: 'guanwang',
                        token: 'd5d7b0304c4b48c59c4419d84c2188c2',
                    }),
                    contentType: 'application/json',
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true,
                    },
                })
                    .success(function () {
                        $('#submiting').hide();
                        $('#submit').fadeIn().removeClass('disabled');
                        $('#contact')[0].reset();
                        $('#proSuccess').show();
                        $('body').css('overflow', 'hidden');
                        setTimeout(function () {
                            $('body').css('overflow', 'auto');
                            $('#proSuccess').hide();
                        }, 3000);
                        // baidu tuiguang 表单提交成功埋码
                        window._agl && window._agl.push(['track', ['success', { t: 3 }]]);

                        // oCPC
                        $.ajax({
                            method: 'POST',
                            url: host + '/auto/user/ocpc',
                            data: {
                                logidUrl: location.origin + $.getCookie('search') || '',
                                convertType: 3,
                            },
                            dataType: 'json',
                            crossDomain: true,
                            xhrFields: {
                                withCredentials: true,
                            },
                        });

                        $.ajax({
                            method: 'POST',
                            url: host + '/page/auto/register_stat',
                            data: {
                                email: $('#from').val(),
                                puid: $.cookie().puid || $.randomString(),
                                from: $.cookie().from || '',
                                desc: $.cookie().desc || '',
                                sub_from: $.cookie().sub_from || 'from_email',
                            },
                            dataType: 'json',
                            crossDomain: true,
                            xhrFields: {
                                withCredentials: true,
                            },
                        });
                    })
                    .error(function () {
                        $('#submiting').hide();
                        $('#submit').fadeIn().text('发送失败');
                    });
            }
        })
        .on('submit', function (ev) {
            ev.preventDefault();
        });

    // 首页 产品咨询 鼠标移动到1v1咨询时，弹出二维码
    $('.pro-describe-text1').bind({
        mouseenter: function (e) {
            $('.pro-con-qrcode').css('opacity', '1');
            $('.pro-con-trip').css('opacity', '1');
            $('.pro-con-trip2').css('opacity', '1');
        },
        mouseleave: function (e) {
            $('.pro-con-qrcode').css('opacity', '0');
            $('.pro-con-trip').css('opacity', '0');
            $('.pro-con-trip2').css('opacity', '0');
        },
    });

    $('.top-bar-right .login-link, .footer-column .login-link').on('click', function (e) {
        const host = location.host.includes('pingxx') ? 'https://dashboard.pingxx.com' : 'https://dashboard.pinpula.com';
        $.ajax({
            method: 'POST',
            url: host + '/auto/user/market_track_click',
            data: { type: 'register_click' },
            dataType: 'json',
            crossDomain: true,
            xhrFields: {
                withCredentials: true,
            },
        }).complete(function () {
            window.location.href = 'https://passport.pingxx.com/login';
        });
    });
});
