const path = window.location.href;

// 仅在定价页面中执行
$(function () {
    if (path.indexOf('download') > -1) {
        const ajaxHost = location.host.includes('pingxx') ? 'https://dashboard.pingxx.com' : 'https://dashboard.pinpula.com';

        const baseUrl = ajaxHost + '/api/pay_college';


        // 文件映射
        const filenameMap = {
            lvmama001: '驴妈妈从0到1搭建支付风控系统-Ping++ 支付设计大会分享.pdf.zip',
            bilibili002: 'Bilibili 业务发展下的交易系统演进思路-Ping++ 支付设计大会分享.pdf.zip',
            minsheng003: '民生银行资金管理解决方案-Ping++ 支付设计大会分享.pdf.zip',
            pingpp004: '账务对账全面解析-Ping++ 支付设计大会分享.pdf.zip',
            '3corfpcore1': '三节课学懂支付核心（上）.zip',
            '3corfpcore2': '三节课学懂支付核心（中）.zip',
            '3corfpcore3': '三节课学懂支付核心（下）.zip',
            wtliquicore: '深度解析：什么是清算核心？.zip',
            htgtpchanel: '重磅干货！对接支付渠道你需要拥有的防踩坑宝典.pdf.zip',
            clksxxhsdz: '从0开始学习搭建支付系统——核算对账核心.pdf.zip',
        };

        // 检查 cookie
        checkCookies();

        // 初始化验证器
        Foundation.Abide.defaults.patterns['sms'] = /^\d{6}$/;

        // 初始化省份
        const $provinceEl = $('#downloadProvince');

        $.each(AreaConfig, (key, item) => {
            let el = $(`<option value="${item.name}">${item.name}</option>`);
            $provinceEl.append(el);
        });

        // 当省份选择后， 改变字体颜色
        $('#downloadProvince').change(function () {
            if ($(this).val() !== '') {
                $(this).css({color: '#202020'})
            } else {
                $(this).css({color: '#cacaca'})
            }
        });


        // 发送验证码
        $('#sendSms').click(function () {
            if ($(this).hasClass('disabled')) {
                return false;
            }

            const $phoneEl = $('#phone'),
                $El = $('#sendSms');

            if ($.trim($phoneEl.val()) === '') {
                $phoneEl.addClass('is-invalid-input');
                return false;
            }

            // 倒计时
            timer($El, 60, () => {
                $El.text('发送验证码').removeClass('disabled');
            });

            $.ajax({
                method: 'POST',
                url: baseUrl + '/send_validate_sms',
                data: {sms_phone: $phoneEl.val()},
                dataType: 'json'
            }).success(function () {

            }).error(function () {
                $El.text('发送验证码').removeClass('disabled');
            })
        });


        // 校验验证码 并 下一步
        $('#nextStep1').click(function () {

            if ($(this).hasClass('disabled')) {
                return false;
            }

            // 全部验证通过的话，进入下一步

            setTimeout(() => {
                if ($('#downloadstep1 input.is-invalid-input').length === 0) {

                    $(this).text('验证中...').addClass('disabled');

                    $.ajax({
                        method: 'POST',
                        url: baseUrl + '/validate_sms',
                        data: $('#downloadstep1').serialize(),
                        dataType: 'json'
                    }).success((res) => {
                        if (res.status) {
                            $('#downloadstep1').fadeOut(() => {
                                $('#downloadstep2').fadeIn();
                            });
                        } else {
                            $(this).text(res.data.message).addClass('button-error').removeClass('disabled');

                            setTimeout(() => {
                                $(this).text('下一步').removeClass('button-error').removeClass('disabled')
                            }, 4000);
                        }

                    }).error(() => {
                        $(this).text('网络错误').addClass('button-error').removeClass('disabled');

                        setTimeout(() => {
                            $(this).text('下一步').removeClass('button-error')
                        }, 4000);

                    })

                } else {
                    console.log('error')
                }
            }, 0);
        });


        // 提交并下载
        $('#submit').click(function () {
            if ($(this).hasClass('disabled')) {
                return false;
            }

            setTimeout(() => {
                if ($('#downloadstep2 input.is-invalid-input').length === 0) {

                    $(this).text('提交中...').addClass('disabled');

                    const data = $('#downloadstep2').serialize() + `&phone=${$('#phone').val()}&filename=${$.getQueryParameters().filename}`;
                    $.ajax({
                        method: 'POST',
                        url: baseUrl + '/stats_report',
                        data: data,
                        dataType: 'json'
                    }).success((res) => {
                        if (res.status) {
                            $('#downloadstep2').fadeOut(() => {
                                $('#downloadstep3').fadeIn();

                                startDownload();

                                setCookies(data);
                            });
                        } else {
                            $(this).text(res.data.message).addClass('button-error').removeClass('disabled');

                            setTimeout(() => {
                                $(this).text('下一步').removeClass('button-error')
                            }, 4000);
                        }
                    }).error(() => {
                        $(this).text('网络错误').addClass('button-error').removeClass('disabled');

                        setTimeout(() => {
                            $(this).text('下一步').removeClass('button-error')
                        }, 4000);
                    })


                } else {
                    console.log('error')
                }
            }, 0);
        });

        function timer($El, time, func) {
            $El.addClass('disabled').text(time);
            if (time === 0) {
                func();
            } else {
                setTimeout(() => {
                    timer($El, --time, func)
                }, 1000);
            }
        }

        function startDownload() {
            const fileName = $.getQueryParameters().filename;

            const realFile = filenameMap[fileName];

            const url = "/download-blog/" + realFile;

            // let win = window.open('about:blank', '_blank');
            // win.location = url;


            let openLink = $("#downloadUrl");
            openLink.attr('href', url);
            openLink[0].click();

            // window.location.href = url;
        }

        function checkCookies() {
            const data = $.cookie().downloadInfo;
            if (data) {
                // 直接成功
                $('#downloadstep3').fadeIn();

                // 更新 cookie
                setCookies(data.replace(/filename=(.*)/, 'filename='+ $.getQueryParameters().filename));

                $.ajax({
                    method: 'POST',
                    url: baseUrl + '/stats_report',
                    data: data,
                    dataType: 'json'
                }).success((res) => {
                    if (res.status) {
                        startDownload();
                    } else {
                        console.error('error')
                    }
                })
            } else {
                $('#downloadstep1').fadeIn();
            }

        }

        function setCookies(data) {
            $.cookie('downloadInfo', data, {expires: 30})
        }
    }
});