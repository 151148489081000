$(document).ready(function () {
    const path = window.location.href;

    // 仅在首页展示
    if (path.indexOf('html') < 0) {
        // 切换分账处理/交易数据
        $('.service-box').on('click', '.prev.active', function () {
            $('.service-content').removeClass('right').addClass('left');
            $(this).removeClass('active').siblings().addClass('active');
        });
        $('.service-box').on('click', '.next.active', function () {
            $('.service-content').removeClass('left').addClass('right');
            $(this).removeClass('active').siblings().addClass('active');
        });

        // 客户赠言区动画效果
        var current = 0; // 当前banner的index,从1开始
        var timmer = null; // 定时器
        const size = $('.message-banner-slide').size(); // 共有多少项
        const item_width = $('.message-banner-slide').eq(1).width(); //每一项的宽度
        // 定义一个鼠标滑过判断事件
        $('.message-banner-slide').onmouseover = function () {
            clearInterval(timmer);
        };
        $('.message-banner-slide').onmouseout = function () {
            timmer = setInterval(slider, 3000);
        };
        function slider() {
            autoSlider();
            current++;
        }
        function autoSlider() {
            if (current === 0 || current === size - 1) {
                $('.point-item').eq(0).addClass('active').siblings().removeClass('active');
            } else {
                $('.point-item').eq(current).addClass('active').siblings().removeClass('active');
            }
            // 圆点按钮轮播
            $('.point-item')
                .removeClass('active')
                .eq(current % (size - 1))
                .addClass('active');
            // 图片轮播
            $('.message-banner-container')
                .stop()
                .animate({ left: -current * item_width + 'px' }, 1000, function () {
                    if (current === size) {
                        // 最后一个图片是1的副本
                        current = 0; // 最后一个图片的时候切换到第0个
                        $('.message-banner-container').animate({ left: 0 }, 0); // 图片切换过去到
                    }
                });
        }
        timmer = setInterval(slider, 3000);
        // 点击圆点切换图片
        $('.point-item').click(function () {
            current = $(this).index();
            autoSlider();
        });
    }
});
