$(document).ready(function () {
    const path = window.location.href;

    // 仅在 B2B供应链解决方案页面、聚合支付页面中执行
    if (path.indexOf('sol_b2b') > -1 || path.indexOf('products') > -1) {
        $('.box-list').on('click', function () {
            $('.box-list').removeClass('active');
            $(this).addClass('active');
            const scrollPosition = $(this).data('t');
            $('html, body').animate(
                {
                    scrollTop: $('#' + scrollPosition)[0].offsetTop - 100
                },
                { duration: 500, easing: 'swing' }
            );
        });
        // 监听滚动事件，当页面滚动到对应的位置，切换对应的tab
        $(document).scroll(function () {
            var scroH = $(document).scrollTop(); //滚动高度
            const scroll1 = $('#scroll1').position().top;
            const scroll2 = $('#scroll2').position().top;

            let scroll3 = 0;
            const isScroll3Exist = $('#scroll3').length > 0;
            if (isScroll3Exist) {
                scroll3 = $('#scroll3').position().top;
            }
            if (scroH >= scroll3 + $('#scroll3').height() || scroH + 60 < scroll1) {
                $('.service-nav').css({ position: 'static', top: 'auto', left: 'auto', bottom: 'auto' });
            }
            if (scroH + 60 >= scroll1) {
                var offsetLeft = $('.service-nav').offset().left;
                $('.service-nav').css({ position: 'fixed', top: '80px', left: offsetLeft, bottom: 'auto' });
            }

            // if (isScroll3Exist) {
            //     if (scroH >= scroll3 + $('#scroll3').height() - 220) {
            //         $('.service-nav').css({ position: 'static', top: 'auto', left: 'auto' });
            //     }
            // } else {
            //     console.log(scroH, scroll2, scroll1);
            //     if (scroH >= scroll2 + scroll1 - 220) {
            //         $('.service-nav').css({ position: 'static', top: 'auto', left: 'auto', bottom: 'auto' });
            //     }
            // }

            if (path.indexOf('sol_b2b') > -1) {
                const b2bTop = $('#sol-b2b').position().top;
                if (scroH >= scroll2 + b2bTop) {
                    $('.service-nav').css({ position: 'static', top: 'auto', left: 'auto', bottom: 'auto' });
                }
            } else if (path.indexOf('products') > -1) {
                const productTop = $('#aggregate-sol').position().top;
                if (scroH >= scroll2 + productTop) {
                    $('.service-nav').css({ position: 'static', top: 'auto', left: 'auto', bottom: 'auto' });
                }
            }

            if (scroH + 70 >= scroll1) {
                $('.box-list').removeClass('active');
                $('.scroll1').addClass('active');
            }
            if (scroH + 70 >= scroll2) {
                $('.box-list').removeClass('active');
                $('.scroll2').addClass('active');
            }
            if (isScroll3Exist && scroH + 70 >= scroll3) {
                $('.box-list').removeClass('active');
                $('.scroll3').addClass('active');
            }
        });
    }
});
