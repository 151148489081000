var homepageTween = function () {
  // Homepage tweening
  var controller = new ScrollMagic.Controller();

  var homePhone1Tween = TweenMax.to("#phone-1", 1, {className: "+=end"});
  var homePhone2Tween = TweenMax.to("#phone-2", 1, {className: "+=end"});
  var homePhoneShadowTween = TweenMax.to("#phone-shadow", 1, {className: "+=end"});
  var homePhoneShadowInnerTween = TweenMax.to("#phone-shadow .inner", 1, {className: "+=end"});
  var homeFeaturesListTween = TweenMax.staggerFromTo(".iconwall-item", 2, {yPercent: -20, opacity: 0}, {yPercent:0, opacity: 1, ease: Back.easeOut}, 0.15);
  var homeStickLabelTween = TweenMax.staggerFromTo(".stick-label", 2, {yPercent: -20, opacity: 0}, {yPercent:0, opacity: 1, ease: Back.easeOut}, 1);
  var homeDashboardTween = TweenMax.fromTo(".home-feature__bg.dashboard", 2, {xPercent: 20, opacity: 0}, {xPercent:0, opacity: 1, ease: Power3.easeOut});

  // build scene
  var homePhone1Scene = new ScrollMagic.Scene({triggerElement: "#providers", duration: 800, offset: -100})
          .setTween(homePhone1Tween)
          .addTo(controller);

  var homePhone2Scene = new ScrollMagic.Scene({triggerElement: "#providers", duration: 800, offset: -100})
          .setTween(homePhone2Tween)
          .addTo(controller);

  var homePhoneShadowScene = new ScrollMagic.Scene({triggerElement: "#providers", duration: 500, offset: 150})
          .setTween(homePhoneShadowTween)
          .addTo(controller);

  var homePhoneShadowInnerScene = new ScrollMagic.Scene({triggerElement: "#providers", duration: 500, offset: 150})
          .setTween(homePhoneShadowInnerTween)
          .addTo(controller);

  var homeIconWallScene = new ScrollMagic.Scene({triggerElement: "#iconwall", duration: 700, offset: -200})
        .setTween(homeFeaturesListTween)
        .addTo(controller);

  var homeStickLabelScene = new ScrollMagic.Scene({triggerElement: "#providers", duration: 500, offset: -50})
        .setTween(homeStickLabelTween)
        .addTo(controller);

  var homeDashboardScene = new ScrollMagic.Scene({triggerElement: "#dashboard", duration: 700, offset: -500})
        .setTween(homeDashboardTween)
        .addTo(controller);
}


var cmbIntroAnimate = function () {
  var controller = new ScrollMagic.Controller();
  // build scenes
	new ScrollMagic.Scene({triggerElement: "#advantage"})
					.setClassToggle("#device", "animated") // add class toggle
					.addTo(controller);

  var buttonRest = $("#cmbWalletPayReset");
  var start = $("#step1");
  buttonRest.click(function(){
    console.log("click");
    $("#device").removeClass("animated");
  })
  start.click(function(){
    console.log("start");
    $("#device").addClass("animated");
  })
}

var testimonialParallax = function () {
  var controller = new ScrollMagic.Controller();

  // build scenes

  $('.testimonial-card').each(function(){
    var currentElement = this;
    var image = $(this).find('.testimonial__fullbleed')[0];
    var tween = TweenMax.fromTo(image, 2, {'background-position-y': '30%'}, {'background-position-y':'70%'});
    var scene = new ScrollMagic.Scene({triggerElement: currentElement, duration: $(window).height() * 1.5, offset: -$(window).height() * 0.5})
            .setTween(tween)
            .addTo(controller);
  });

}
