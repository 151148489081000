$(function () {
    let baseOption = '<option value="" selected="selected" disabled="disabled">选择</option>';

    let $provinceEl = $('#province').append(baseOption);
    // let $cityEl = $('#city').append(baseOption);
    // let $areaEl = $('#area').append(baseOption);


    // $('#province').on('change', function () {
    //     const child = $(this).find("option:selected").data('child');
    //
    //     initSelect($('#city'), child);
    //     initSelect($('#area'), child[0].child);
    // });
    //
    // $('#city').on('change', function () {
    //     const child = $(this).find("option:selected").data('child');
    //
    //     initSelect($('#area'), child);
    // });


    // 插入省
    initSelect($provinceEl, AreaConfig);

    // // 插入市
    // initSelect($cityEl, AreaConfig[0].child);
    //
    // // 插入区
    // initSelect($areaEl, AreaConfig[0].child[0].child);


    function initSelect(element, config) {
        element.html('');

        element.append(baseOption);

        $.each(config, (key, item) => {
            let el = $(`<option value="${item.name}">${item.name}</option>`);

            el.data('child', item.child);

            element.append(el);
        });
    }
});